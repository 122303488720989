import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["menu"]

    toggle(event) {
        // Find all dropdown menus on the page
        const allDropdowns = document.querySelectorAll("[data-ui--dropdownv2-target='menu']");

        // Close all dropdowns except the one that was clicked
        allDropdowns.forEach(dropdown => {
            if (dropdown !== this.menuTarget) {
                dropdown.classList.add('hidden');
                dropdown.classList.remove('max-h-96');
                dropdown.classList.add('max-h-0');
            }
        });

        // Toggle the clicked dropdown
        this.menuTarget.classList.toggle('hidden');
        this.menuTarget.classList.toggle('max-h-0');
        this.menuTarget.classList.toggle('max-h-96');
    }
}
